<template>
  <a-spin :spinning="loading">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Buat Request Baru</h2>
      </div>
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <!-- <a-button @click="changeEdittable">
            <span v-if="!editable"> <FormOutlined class="mr-2" />Edit </span>
            <span v-else> <EyeOutlined class="mr-2" />View </span>
          </a-button> -->
        </a-col>
        <a-col :span="24" v-if="false">
          <div>
            <h1 class="text-title">Request Date</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Date</p>
                  <a-date-picker format="DD / MM / YYYY" class=" w-100"></a-date-picker>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Request Detail</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Request Type</p>
                  <a-select placeholder="Pilih Request Type" class="w-100" v-model:value="selectedRequestType"
                    @change="handleChangeRequestType" :disabled="role === 'eos'">
                    <a-select-option v-for="(item, index) in dataRequestType" :key="index" :value="item">{{ item
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Category</p>
                  <a-select placeholder="Pilih Asset Category" class="w-100" v-model:value="selectedCategories"
                    @change="handleChangeCategories" :disabled="!selectedRequestType">
                    <a-select-option v-for="(item, index) in dataAssetCategories" :key="index" :value="item.id">{{
    item.name }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Sub Category</p>
                  <a-select placeholder="Pilih Asset Sub Category" class="w-100" v-model:value="selectedSubCategories"
                    :disabled="!selectedCategories" @change="handleChangeSubCategories">
                    <a-select-option v-for="(item, index) in dataAssetSubCategories" :key="index" :value="item.id">{{
    item.name }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset</p>
                  <a-select placeholder="Pilih Asset" class="w-100" v-model:value="selectedAsset"
                    :disabled="!selectedSubCategories || !dataAssets.length" @change="handelChangeAssets">
                    <a-select-option v-for="(item, index) in dataAssets" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <!--  -->
              <!--  -->
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Brand</p>
                  <a-select placeholder="Pilih Manufacturer" class="w-100"
                    v-model:value="dataSelectedAsset.manufacturerId" disabled>
                    <a-select-option v-for="(item, index) in dataManufatures" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Model</p>
                <a-input placeholder="Masukkan Model" v-model:value="dataSelectedAsset.modelAsset" disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Product No</p>
                <a-input placeholder="Masukkan Product No" v-model:value="dataSelectedAsset.productNumber"
                  disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Serial No</p>
                <a-input placeholder="Masukkan Serial No" v-model:value="dataSelectedAsset.serialNumber"
                  disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Service Tag</p>
                <a-input placeholder="Masukkan Service Tag" v-model:value="dataSelectedAsset.serviceTag"
                  disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Asset Tag</p>
                <a-input placeholder="Masukkan Asset Tag" v-model:value="dataSelectedAsset.assetTag" disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <p style="text-align: left;" class="text-label">Asset Tag</p>
                <a-textarea placeholder="Masukkan Notes" v-model:value="dataPayload.notes"></a-textarea>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <a-button :disabled="!selectedAsset && selectedRequestType !== 'Addition'"
                @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification, Form } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import {
  getCustomers,
  getLocationByCustomerId,
  getRoomByCustomerId,
} from '../../services/axios/modc/customer'
import {
  getCategories,
  postAssets,
  getAssetCategory,
  getAssets,
} from '../../services/axios/modc/assets'
import { getManufactures } from '../../services/axios/modc/manufacture'
import { getVendors } from '../../services/axios/modc/vendor'
import { createChangeRequest } from '../../services/axios/modc/helpdesk'
import { createChangeRequestEos } from '../../services/axios/modc/eos'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'

const columns = [
  {
    title: 'Nama Perangkat',
    dataIndex: 'device',
    key: 'device',
    slots: { customRender: 'device' },
    width: '15%',
  },
  {
    title: 'Nama Brand',
    dataIndex: 'brand',
    key: 'brand',
    slots: { customRender: 'brand' },
  },
  {
    title: 'Nomor Produk',
    dataIndex: 'productNo',
    key: 'productNo',
    slots: { customRender: 'productNo' },
  },
  {
    title: 'Nomor Serial',
    dataIndex: 'serialNo',
    key: 'serialNo',
    slots: { customRender: 'serialNo' },
  },
]
const useForm = Form.useForm
const assetsId = [
  // FACILITIES
  7 /**Elec Pan comps*/,
  8 /**UPS comps*/,
  10 /**Generator Set comps*/,
  11 /**Battery comps*/,
  12 /**Grounding */,
  13 /**Pre AC */,
  14 /**Sta AC */,
  15 /**Fire Supp comps*/,
  16 /**Fire Sup pip */,
  17 /**Fire Ext comps*/,
  18 /**CCTV comps*/,
  19 /**Door comps*/,
  20 /**ENV comps*/,
  // ACTIVE
  22 /** Access Point */,
  23 /** Firewall */,
  24 /** Router */,
  25 /** Server */,
  26 /** Switch */,
  // PASSIVE
  3 /** RACK UNIT */,
  27 /** UTP Cable */,
  28 /** UTP Patch Panel */,
  29 /** FO Patch Panel */,
  30 /** FO Cable */,
]

export default {
  components: { LeftOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()

    const assets = ref()
    // const manufactures = ref()
    const loading = ref(false)
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const selectedTypeofFailure = ref()
    const selectedTypeofSolving = ref()
    const selectedReparation = ref()
    const editable = ref(false)
    const componentsAssetsData = ref([])
    const componentsHardwareProcessor = ref([])
    const componentsHardwareDiskDrive = ref([])
    const componentsHardwareMemory = ref([])

    // CUSTOMER
    const selectedCustomer = ref()
    const dataCustomers = ref([])
    const selectedLocation = ref()
    const dataLocation = ref([])
    const selectedRoom = ref()
    const dataRoom = ref([])

    // CATEGORIES
    const dataRequestType = ref(['Troubleshooting', 'Addition', 'Dismantling', 'Testing'])
    const selectedRequestType = ref()

    const dataAssetCategories = ref([])
    const selectedCategories = ref()

    const dataAssetSubCategories = ref([])
    const selectedSubCategories = ref()

    const dataAssets = ref([])
    const selectedAsset = ref()

    // BRAND/MANUFACTURE
    const dataManufatures = ref([])
    const selectedManufatures = ref()

    // VENDORS
    const dataVendors = ref([])
    const selectedVendors = ref()
    const selectedVendors2 = ref()

    // const addTroubleshootDetail = () => {
    //   componentsAssetsData.value.push({
    //     _id: uniqueId('tbs-'),
    //     name: '',
    //     quantity: '',
    //     location: '',
    //   })
    // }
    // const deleteTroubleshootDetail = id => {
    //   componentsAssetsData.value = componentsAssetsData.value.filter(trb => trb._id !== id)
    // }
    // const addHardwareProcessor = () => {
    //   componentsHardwareProcessor.value.push({
    //     _id: uniqueId('tbs-'),
    //     manufacturerId: null,
    //     model: null,
    //     speed: null,
    //     threads: null,
    //     core: null,
    //   })
    // }
    // const deleteHardwareProcessor = id => {
    //   componentsHardwareProcessor.value = componentsHardwareProcessor.value.filter(
    //     trb => trb._id !== id,
    //   )
    // }
    // const addHardwareDiskDrive = () => {
    //   componentsHardwareDiskDrive.value.push({
    //     _id: uniqueId('tbs-'),
    //     manufacturerId: null,
    //     model: null,
    //     speed: null,
    //     threads: null,
    //     core: null,
    //   })
    // }
    // const deleteHardwareDiskDrive = id => {
    //   componentsHardwareDiskDrive.value = componentsHardwareDiskDrive.value.filter(
    //     trb => trb._id !== id,
    //   )
    // }
    // const addHardwareMemory = () => {
    //   componentsHardwareMemory.value.push({
    //     _id: uniqueId('tbs-'),
    //     manufacturerId: null,
    //     model: null,
    //     speed: null,
    //     threads: null,
    //     core: null,
    //   })
    // }
    // const deleteHardwareMemory = id => {
    //   componentsHardwareMemory.value = componentsHardwareMemory.value.filter(trb => trb._id !== id)
    // }
    onBeforeMount(() => {
      fetchInitialData()
    })

    const dataPayload = ref({
      type: null,
      assetId: null,
      helpDeskId: null,
      notes: null,
      assetCategoryId: null,
      assetSubCategoryId: null,
    })
    const dataSelectedAsset = ref({
      manufacturerId: null,
      modelAsset: null,
      productNumber: null,
      serialNumber: null,
      serviceTag: null,
      assetTag: null,
    })
    const rulesPayload = ref({
      wifiName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      availablePort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      workingPort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      deadPort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      ipAdress: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      subnetMask: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      gateway: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      totalPort: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      systemName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      hostName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      domainRole: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      dns: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      os: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      softwareName: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      softwareVersion: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      accessType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      airFlow: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      coolingDesign: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      coolingType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      coolingCapacity: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      inputPowerKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      panelType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      transferSwitch: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      phaseType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      sensorType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      supressType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      fillDate: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      expireDate: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      extenguisherType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      kva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      kw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      machineType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      systemTrigger: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      model: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      formFactor: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      battery: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      height: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      width: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      depth: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      weight: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      backupKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      backupKva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      backupDuration: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      inputKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      inputKva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      outputKw: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      outputKva: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      batteryType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      voltage: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      cells: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      controllerStatus: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      utpType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      node: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      core: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      connectionType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      patch: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      cableManagement: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      rackUnit: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      crossConnection: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      foType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      softwareType: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      version: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      customerId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      locationId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      roomId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      assetSubCategoryId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      manufacturerId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      vendorId: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      name: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      modelAsset: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      productNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      serialNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      serviceTag: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      assetTag: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      rackNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      uNumber: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      uSize: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      status: [
        {
          required: true,
          message: 'Please input name',
        },
      ],
      diskDrives: [
        // {
        //   model: 'Disk Drive Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      memories: [
        // {
        //   model: 'Memory Model 1',
        //   serialNumber: 'SN1234567890A',
        //   capacity: '128 GB',
        // },
      ],
      processors: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      components: [
        // {
        //   model: 'Processor Model 1',
        //   speed: '3.6 GHz',
        //   threads: 6,
        //   cores: 6,
        // },
      ],
      procurements: {
        vendorId: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        No: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        Date: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        value: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warranty: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyStart: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyEnd: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
      },
      maintenances: {
        vendorId: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        mobileNumber: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        No: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        Date: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        value: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warranty: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyStart: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
        warrantyEnd: [
          {
            required: true,
            message: 'Please input name',
          },
        ],
      },
    })

    // const { resetFields, validate, validateInfos } = useForm(dataPayload, rulesPayload)
    const role = ref('')
    const fetchInitialData = async () => {
      const customers = await getCustomers()
      dataCustomers.value = customers.customer
      const categories = await getCategories()
      const assetCategory = await getAssetCategory()
      const filteredAssetCategory = assetCategory.categories.filter(
        cat => cat.name != 'Software' && cat.name != 'Infrastructure' && cat.name != 'Monitoring',
      )

      console.log(filteredAssetCategory, '<<<ASET CATEGORY')
      let catAssets

      const manufactures = await getManufactures()
      dataManufatures.value = manufactures.manufacturers

      const vendors = await getVendors()
      dataVendors.value = vendors.vendor
      console.log(vendors, '<<<<<CATEGORIES')
      const userLogin = JSON.parse(localStorage.getItem('userData'))
      role.value = userLogin.user.role

      // console.log(userLogin.user.id)
      if (userLogin.user.role === 'helpDesk') {
        dataPayload.value.helpDeskId = userLogin.user.id
      } else if (userLogin.user.role === 'eos') {
        selectedRequestType.value = 'Troubleshooting'
        handleChangeRequestType('Troubleshooting')
        dataPayload.value.eosId = userLogin.user.id
      }

    }
    const handleChangeCustomer = async e => {
      const location = await getLocationByCustomerId(e)
      dataLocation.value = location.locations
      dataPayload.value.customerId = e
    }
    const handleChangeLocation = async e => {
      const room = await getRoomByCustomerId(selectedCustomer.value, e)
      // console.log(room)
      dataRoom.value = room.rooms
      dataPayload.value.locationId = e
    }
    const handleChangeRoom = async e => {
      dataPayload.value.roomId = e
    }

    const handleChangeRequestType = async e => {
      const assetCategory = await getAssetCategory()
      const filteredAssetCategory = assetCategory.categories.filter(
        cat => cat.name != 'Software' && cat.name != 'Infrastructure' && cat.name != 'Monitoring',
      )
      // const lastAssetCategory = filteredAssetCategory.filter(cat => {
      //   if (e == 'Troubleshooting') {
      //     if (cat.name != 'Passive Devices') return cat
      //   } else return cat
      // })
      dataAssetCategories.value = filteredAssetCategory
      dataPayload.value.type = e
      // dataAssetSubCategories.value = lastAssetCategory
    }
    const handleChangeCategories = async e => {
      const subCategories = dataAssetCategories.value.find(cat => cat.id == e)
      dataAssetSubCategories.value = subCategories.AssetSubCategories
      dataPayload.value.assetCategoryId = subCategories.id
    }
    const handleChangeSubCategories = async e => {
      selectedAsset.value = null
      dataPayload.value.assetId = null
      const userLogin = JSON.parse(localStorage.getItem('userData'))
      const assets = await getAssets({ type: e }, null, null, null, null, userLogin.user.id, userLogin.user.role)
      dataAssets.value = assets.assets
      dataPayload.value.assetSubCategoryId = e
      // selectedAsset.value.assetId = null
    }

    const handelChangeAssets = async e => {
      const assets = dataAssets.value.find(asset => asset.id == e)
      dataPayload.value.assetId = assets.id
      dataSelectedAsset.value = assets
      console.log(assets, '<<<ASSETs')
    }

    const handleChangeProcurementVendor = async e => {
      const vendorProcurement = dataVendors.value.find(data => data.id == e)
      dataPayload.value.procurements.email = vendorProcurement.email
      dataPayload.value.procurements.mobileNumber = vendorProcurement.mobileNumber
    }
    const handleChangeMaintenancesVendor = async e => {
      const vendorMaintenance = dataVendors.value.find(data => data.id == e)
      dataPayload.value.maintenances.email = vendorMaintenance.email
      dataPayload.value.maintenances.mobileNumber = vendorMaintenance.mobileNumber
    }
    // const changeEdittable = () => {
    //   editable.value = !editable.value
    // }
    const handleSubmit = async () => {
      const payload = dataPayload.value

      const userLogin = JSON.parse(localStorage.getItem('userData'))
      // console.log(userLogin.user.id)
      let res
      if (userLogin.user.role === 'helpDesk') {
        res = await createChangeRequest(payload)
        // dataPayload.value.helpDeskId = userLogin.user.id
      } else if (userLogin.user.role === 'eos') {
        res = await createChangeRequestEos(payload)
        // dataPayload.value.eosId = userLogin.user.id
      }
      if (res) {
        notification.success({
          message: 'Berhasil Submit',
          description: 'Data Change Request Berhasil Dibuat',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      router.push('/request')
      // console.log('/' + route.path.split('/').at(1) + '/' + routeName, '<<<<PAYLOAD')
    }

    return {
      role,
      columns,
      loading,
      // detail troubleshoot
      facility,
      catFacility,
      componentsAssetsData,
      componentsHardwareProcessor,
      componentsHardwareDiskDrive,
      componentsHardwareMemory,
      // Last Section

      handleSubmit,

      // DATA CUSTOMER
      selectedCustomer,
      dataCustomers,
      selectedLocation,
      dataLocation,
      selectedRoom,
      dataRoom,
      // FUNCTION
      handleChangeCustomer,
      handleChangeLocation,
      handleChangeRoom,
      handleChangeRequestType,
      handleChangeProcurementVendor,
      handleChangeMaintenancesVendor,
      handleChangeCategories,
      handleChangeSubCategories,
      handelChangeAssets,
      // DATA CUSTOMER

      // CATEGORY ASSETS
      dataRequestType,
      selectedRequestType,
      dataAssetCategories,
      selectedCategories,
      dataAssetSubCategories,
      selectedSubCategories,
      dataAssets,
      selectedAsset,
      // CATEGORY ASSETS

      // MANUFACTURE
      dataManufatures,
      selectedManufatures,
      // MANUFACTURE

      // VENDORS
      dataVendors,
      selectedVendors,
      selectedVendors2,
      // VENDORS

      // CAPACITY
      // capacityOption,
      // CAPACITY

      // PASSIVE
      // utpType,
      // connectionType,
      // foType,
      // foConnectionType,
      // PASSIVE

      // SOFTWARE
      // dataSoftwareType,
      // SOFTWARE

      // DATA PAYLOAD
      dataPayload,
      dataSelectedAsset,
      // validateInfos,
      // DATA PAYLOAD
      // dataSource,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
